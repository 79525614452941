<template>
    <div>
        <h1>whitepaper</h1>
        <h2>navigation</h2>

        <div class="navigation">
        	<a href="#philosophy"><span>0.</span>philosophy</a>
            <a href="#art"><span>1.</span>art</a>
        	<a href="#venues-djs"><span>2.</span>djs & venues</a>
            <a href="#utility"><span>3.</span>utility</a>
        	<a href="#scalability"><span>4.</span>scalability</a>
        	<a href="#roadmap"><span>5.</span>roadmap</a>
            <a href="#mint"><span>6.</span>mint</a>
            <a href="#whitelist"><span>7.</span>whitelisting</a>
            <a href="#author"><span>8.</span>author</a>
            <a href="#disclaimer"><span>9.</span>disclaimer</a>
        </div>

        <h2 id="philosophy" class="mt-5">philosophy</h2>
        <p class="justify">This project aims to support electronic & techno music DJs and venues. Many famous techno clubs closed their doors because of the pandemic. Many artists suffered from it and ended their careers just to do something else that is not impacted as much as the club culture. This project is a second breath for these actors as it will offer them a stable revenue in exchange for some exclusive content and real-life access for guestlisted.club NFT owners.</p>

        <h2 id="art" class="mt-5">evolutive, on-chain art</h2>
        <p class="justify">The collection consists of 256 SVG posters of techno events with different DJs and clubs. Each poster is unique. The art is generated and stored on the Ethereum blockchain, with no external storage / IPFS services involved. Each poster comprises 6 more or less rare traits: DJ, Venue, Date, Shape (circle / line / square / prism / cube), Color, and Background. The animation of each poster's shape is evolutive. Every time you call the tokenUri() contract method or refresh the metadata on a secondary market like Opensea or LooksRare, the animation will evolve depending on the current ethereum blockchain block time and difficulty. Also, tokenImage() method is provided in the contract and allows retrieving the image of a given token id in base64 encoded SVG format.</p>

        <h2 id="venues-djs" class="mt-5">djs & venues</h2>
        <p class="justify mb-3">Ten exclusive Berlin techno venues and 20 techno DJs from all over the world will be selected meticulously and contacted to bring this project to life. We will add them one by one as our collaborations progress, and the diagram below will be updated.</p>
        <img alt="guestlisted" class="img-fluid" src="@/assets/img/venues-djs-grey.svg"/>


        <h2 id="utility" class="mt-5">utility</h2>
        <p class="justify">The main utility of NFT tokens will be to grant their owners a guestlist (free access without queuing) to exclusive techno clubs and some exclusive content from DJs. This will be achieved trhough collaborations with DJs and music venues.<br/><br/>

        Let's take the example of the Berlin clubs. Every weekend there are thousands of people trying to attend its parties, and their chances of getting in are small even after queuing in front of the clubs for hours. However, owning one of guestlisted NFTs could grant you access to the club's events without queuing (no promises here). Even Elon Musk already twitted about these clubs.<br/><br/>

        Collaborations with clubs and DJs will be entirely funded with secondary market fees (5% will be allocated for collaborations and to fund the project development). First collaborations will bring more interest to the project and increase the trading volume on secondary markets.<br/><br/>

        What if you own a guestlisted.club NFT but are not physically located nearby one of the clubs ? Don't worry. We plan to develop a renting system that will allow you to rent your NFT for an event. Also, we have a scalability feature in our contract that will allow us (in the future) to expand our collaborations to other clubs and DJs in other countries. It's a long-term project that aims to support DJs and venues and provide benefits for owners on a long-term basis.</p>

        <h2 id="scalability" class="mt-5">scalability</h2>
        <p class="justify">We implemented a scalability feature in our ERC721 contract that will allow in the future (and if the project works out well) to add more DJs and clubs to the party and to mint more tokens. The price of the mint of the new tokens can also be regulated so as not to hinder the current owners. This project can become the benchmark in the club culture.</p>

        <h2 id="roadmap" class="mt-5">roadmap</h2>
        <ol class="roadmap">
            <li><s>Whitelisting & Minting</s></li>
            <li><s>First collaborations</s></li>
            <li><s>Events page & guestlist claiming system</s></li>
            <li>Expanding collaborations <b class="small">(IN PROGRESS)</b></li>
            <li>Renting system</li>
            <li>Future scaling of the project</li>
        </ol>

        <h2 id="mint" class="mt-5">mint</h2>
        <p class="justify">The collection has been minted out. There were 256 mintable NFTs; 6 were reserved for the project creator, and the remaining 250 were minted by whitelisted users in stealth, random and fair drop. There was no public mint. Members who had successfully passed the whitelisting process could only mint one NFT. The mint was free; only gas fees had to be paid to the blockchain.</p>

        <h2 id="whitelist" class="mt-5">whitelisting</h2>
        <p class="justify">The whitelisting process was automated entirely via twitter and discord bots. Users had to resolve a riddle. As in real life (in Berlin), users had to know who was playing and some other information to access the club and obtain a unique signature allowing to (free) mint.<br><br>
        The name of the DJ playing was displayed on our website. The event's location was in the location of our twitter account, and the date was in the logs channel on discord. After each given wl (signature), all these parameters were modified automatically by the bot.<br/><br>
        Once users got their unique signature, they could fill it on our website's mint page and mint a random poster. There were 2154 tweets posted by 517 users and 270 of them have been whitelisted.
        </p>

        <h2 id="author" class="mt-5">author</h2>
        <p class="justify">This project was created by <a href="https://twitter.com/etherlect" target="_blank">@etherlect</a> - web & solidity developer, digital artist, techno music lover, and crypto & NFT enthusiast. He has some great connections in Berlin that will help achieve the project's goal.</p>

        <h2 id="disclaimer" class="mt-5">disclaimer</h2>
        <p class="justify mb-0">No one can assure that an NFT project works out well or not, so please, invest only what you can afford to lose. Any privileged access is not guaranteed; guestlisted members have to understand the Berlin club culture, mentality, dress code, and door policy of the club. You can still be refused entry if you do not respect those factors, even if you're guestlisted. Join our discord and ask questions to our community to maximize your odds of getting in. Good luck and have fun !</p>
        
    </div>
</template>

<script>

export default {
    name: 'Whitepaper',
    components: {},
    data () {
        return {}
    },
    props: {},
    watch: {},
    computed: {},
    mounted () {},
    methods: {}
}
</script>

<style lang="scss">
	.navigation {
		margin-bottom: 36px;
		a {
			display: block;
			font-weight: 500;
			text-decoration: none;
			font-size: 20px;

			span {
				width: 25px;
				display: inline-block;
			}
		}
	}
</style>